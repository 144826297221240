<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.2775 9.0275C6.0875 9.0275 5.8975 8.9575 5.7475 8.8075L2.2175 5.2775C1.9275 4.9875 1.9275 4.5075 2.2175 4.2175C2.5075 3.9275 2.9875 3.9275 3.2775 4.2175L6.2775 7.2175L9.2775 4.2175C9.5675 3.9275 10.0475 3.9275 10.3375 4.2175C10.6275 4.5075 10.6275 4.9875 10.3375 5.2775L6.8075 8.8075C6.6575 8.9575 6.4675 9.0275 6.2775 9.0275Z"
      fill="#9984D4"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowDropSvg",
};
</script>

<style scoped></style>
