export default {
  data: () => ({
    skip: 0,
    limit: 9,
    loaderDisable: false,
  }),
  methods: {
    resetPreviousLoading() {
      this.skip = 0;
      this.cshCommunity = [];
      this.loaderDisable = false;
    },
  },
};
