<template>
  <div class="popup-container">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-collection">
        <div class="popup-swipe" v-touch:swipe.bottom="close">
          <span></span>
        </div>
        <div class="popup__head">
          <div class="popup__title">
            {{ $t("mates-my-col") }}
          </div>

          <div class="align-center">
            <MainButton :text="$t('reset-filters-btn')" @click="resetFilter" />

            <PageButton @click="close">
              <template v-slot:icon>
                <Close />
              </template>
            </PageButton>
          </div>
        </div>
        <div class="popup__content">
          <div class="justify-between">
            <Filters @filter="makeFilter" v-if="showFilter" />

            <Sort
              @toggleFilter="toggleFilter"
              @sortByPhenotype="sortByPhenotype"
              :type="sortType"
              ref="Sort"
            />
          </div>

          <div class="mates-list mates-list--grid">
            <MateCard
              v-for="(item, index) in cshOwn"
              :key="index"
              :mate="item"
              :show-character-commissions="showCharacterCommissions"
              :show-character-stats="showCharacterStats"
              @click="selectMate"
            />
          </div>
          <ScrollLoader
            :loader-method="getUserCryptoshoppies"
            :loader-disable="loaderDisable"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MainButton from "../common/buttons/MainButton";
import PageButton from "../common/buttons/PageButton";
import Close from "../icons/Close";
import Filters from "../common/Filters";
import Sort from "../common/Sort";
import CloseModalMixin from "../../mixins/common/CloseModalMixin";
import MateCard from "@/components/breeding/MateCard";
import api from "@/api/api";
import DefaultScrollLoadingMixin from "@/mixins/common/DefaultScrollLoadingMixin";

export default {
  name: "MyCollection",
  mixins: [CloseModalMixin, DefaultScrollLoadingMixin],
  props: {
    showCharacterCommissions: {
      type: Boolean,
      default: true,
    },
    showCharacterStats: {
      type: Boolean,
      default: false,
    },
    showFilter: {
      type: Boolean,
      default: () => true,
    },
    sortType: {
      type: String,
      default: () => "DEFAULT",
      validator: (val) => ["DEFAULT", "SKILLS"].includes(val),
    },
    loaderMethod: {
      type: String,
      required: false,
      default: () => "getUserCryptoshoppies",
    },
  },
  data: () => ({
    cshOwn: [],
    filter: "",
    sort: null,
    phenotypeSort: null,
  }),
  methods: {
    sortByPhenotype(val) {
      this.phenotypeSort = val;
      this.cshOwn = [];
      this.resetPreviousLoading();
    },
    resetFilter() {
      this.$refs.Sort.resetFilter();
    },
    toggleFilter(val) {
      this.sort = val;
      this.cshOwn = [];
      this.resetPreviousLoading();
    },
    makeFilter(filter) {
      this.filter = filter;
      this.resetPreviousLoading();
    },
    selectMate(val) {
      this.$emit("selectMate", val);
    },
    async getUserCryptoshoppies() {
      try {
        let priceSort = null;
        let raritySort = null;
        let skillSort = null;
        if (this.sortType === "DEFAULT") {
          if (this.sort === "price-high-to-low") {
            priceSort = 1;
            raritySort = -1;
          }
          if (this.sort === "price-low-to-high") {
            priceSort = -1;
            raritySort = 1;
          }
          if (this.sort === "rarity-high-to-low") {
            raritySort = 1;
            priceSort = -1;
          }
          if (this.sort === "rarity-low-to-high") {
            raritySort = -1;
            priceSort = 1;
          }
        }
        if (this.sort === "recommended") {
          priceSort = null;
          raritySort = null;
          skillSort = null;
        }
        if (this.sortType === "SKILLS") {
          if (this.sort === "strength") {
            skillSort = "skills.strength:desc";
          }
          if (this.sort === "endurance") {
            skillSort = "skills.endurance:desc";
          }
          if (this.sort === "agility") {
            skillSort = "skills.agility:desc";
          }
          if (this.sort === "intelligence") {
            skillSort = "skills.intelligence:desc";
          }
        }

        const response = await api[this.loaderMethod]({
          skip: this.skip,
          limit: this.limit,
          filters: this.filter || null,
          price: priceSort,
          rarity: raritySort,
          sort: skillSort,
          sortByMask: this.phenotypeSort,
        });
        this.skip += this.limit;
        this.cshOwn = [...this.cshOwn, ...response.cryptoshoppies];
        this.loaderDisable = response.cryptoshoppies.length < this.limit;
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    MateCard,
    Sort,
    Filters,
    Close,
    PageButton,
    MainButton,
  },
};
</script>

<style scoped></style>
