<template>
  <div class="sort">
    <div class="sort__title">
      {{ $t("sort-title") }}
    </div>

    <button class="sort-btn" type="button" @click="isModalVisible = true">
      {{
        (sortCurrent.charAt(0).toUpperCase() + sortCurrent.slice(1)).replaceAll(
          "-",
          " "
        )
      }}
      <ArrowDropSvg />
    </button>
    <transition name="fade">
      <keep-alive>
        <SortModal
          v-show="isModalVisible"
          @close="isModalVisible = false"
          @change="toggleFilter"
          @sortByPhenotype="sortByPhenotype"
          :type="type"
          ref="SortModal"
        />
      </keep-alive>
    </transition>
  </div>
</template>

<script>
import ArrowDropSvg from "../icons/ArrowDropSvg";
import SortModal from "../modals/SortModal";
export default {
  name: "Sort",
  components: { SortModal, ArrowDropSvg },
  props: {
    type: {
      type: String,
      default: () => "DEFAULT",
      validator: (val) => ["DEFAULT", "SKILLS"].includes(val),
    },
  },
  data: () => ({
    sortCurrent: "Recommended",
    isModalVisible: false,
  }),
  methods: {
    sortByPhenotype(val) {
      this.$emit("sortByPhenotype", val);
      this.isModalVisible = false;
    },
    toggleFilter(val) {
      this.$emit("toggleFilter", val);
      this.sortCurrent = val || "Recommended";
      this.isModalVisible = false;
    },
    resetFilter() {
      this.$refs.SortModal.resetFilter();
      this.sortCurrent = "Recommended";
    },
  },
};
</script>

<style scoped></style>
