<template>
  <div
    class="mates-char"
    :class="{ 'mates-char_disabled': !isCharacterAvailable }"
    @click="click"
  >
    <!--    <div class="char-placeholder__badge">-->
    <!--      {{ $t("char-badge") }}-->
    <!--    </div>-->
    <div class="mates-char__img">
      <CharacterModel :image="characterImage" />
    </div>

    <div class="char-stats" v-if="showCharacterCommissions">
      <div class="char-stats__row">
        <div class="char-stats__title">
          {{ $t("char-stat-rarity") }}
        </div>

        <div class="char-stats__amount">50-75</div>
      </div>

      <div class="char-stats__row">
        <div class="char-stats__title">
          {{ $t("char-stat-sub") }}
        </div>

        <div class="char-stats__amount">
          {{ formatNumber(characterSubCommission) }}
        </div>
      </div>

      <div class="char-stats__row">
        <div class="char-stats__title">
          {{ $t("char-stat-igm") }}
        </div>

        <div class="char-stats__amount">
          {{ formatNumber(characterIgmCommission) }}
        </div>
      </div>
    </div>

    <div class="char-stats" v-if="showCharacterStats">
      <div
        v-for="([key, value], index) in Object.entries(characterSkills)"
        :key="index"
        class="char-stats__row"
      >
        <div class="char-stats__title">
          {{ $t(`stat-${key}`) }}
        </div>

        <div class="char-stats__amount">{{ value }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import CharacterModel from "@/components/character/СharacterModel";
import loadShoppieByIdMixin from "@/mixins/loadShoppieByIdMixin";
import formatNumberMixin from "@/mixins/common/FormatNumberMixin";
export default {
  name: "MateCard",
  mixins: [loadShoppieByIdMixin, formatNumberMixin],
  props: {
    mate: {
      type: Object,
      required: true,
    },
    isCommunity: {
      type: Boolean,
      required: false,
      default: false,
    },
    showCharacterCommissions: {
      type: Boolean,
      default: true,
    },
    showCharacterStats: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isCharacterAvailable: false,
  }),
  methods: {
    click() {
      if (!this.isCharacterAvailable) {
        return;
      }
      this.$emit("click", this.mate);
    },
  },
  mounted() {
    this.character = this.mate;
    this.isCharacterAvailable = true;
    // this.isCharacterAvailable = this.isCommunity
    //   ? true
    //   : this.isCharacterAllowedForBreeding(this.nextBreedingDateCalculated);
  },
  components: { CharacterModel },
};
</script>

<style scoped>
.mates-char_disabled {
  opacity: 0.5;
}
</style>
