<template>
  <div class="popup-container z-upper">
    <div class="popup-bg"></div>
    <div class="popup-slide">
      <div class="popup popup-collection popup-sort">
        <div class="popup-swipe" v-touch:swipe.bottom="close">
          <span></span>
        </div>
        <div class="popup__head">
          <div class="popup__title">
            {{ $t("sort-title") }}
          </div>

          <PageButton @click="close">
            <template v-slot:icon>
              <Close />
            </template>
          </PageButton>
        </div>
        <ShoppiesList
          :items="eventItems"
          :show-load-more-btn="false"
          :hide-rarity="true"
          :clickable="true"
          :filterable="true"
          @applyAsFilter="applyAsFilter"
        />
        <div
          v-if="defaultFilter"
          class="popup__content"
          style="margin-top: 10px"
        >
          <div class="check-btn">
            <label>
              <span class="check-btn__text">
                <span class="check-btn__name">
                  {{ $t("sort-recommend") }}
                </span>
              </span>
              <input
                type="radio"
                value="recommended"
                name="recommended"
                v-model="filter"
              />
              <span class="check-btn__content">
                <span class="check-btn__box"></span>
              </span>
            </label>
          </div>

          <div class="check-btn">
            <label>
              <span class="check-btn__text">
                <span class="check-btn__name">
                  {{ $t("sort-rarity") }}
                </span>
                <span
                  class="check-btn__subname"
                  :class="{ active: filter === 'rarity-high-to-low' }"
                >
                  {{ $t("sort-high") }}
                </span>
              </span>
              <input
                type="radio"
                value="rarity-high-to-low"
                name="rarity"
                v-model="filter"
              />
              <span class="check-btn__content">
                <span class="check-btn__box"></span>
              </span>
            </label>

            <label>
              <span class="check-btn__text">
                <span class="check-btn__name">
                  {{ $t("sort-rarity") }}
                </span>
                <span
                  class="check-btn__subname"
                  :class="{ active: filter === 'rarity-low-to-high' }"
                >
                  {{ $t("sort-low") }}
                </span>
              </span>

              <input
                type="radio"
                value="rarity-low-to-high"
                name="rarity"
                v-model="filter"
              />
              <span class="check-btn__content">
                <span class="check-btn__box"></span>
              </span>
            </label>
          </div>

          <div class="check-btn">
            <label>
              <span class="check-btn__text">
                <span class="check-btn__name">
                  {{ $t("sort-price") }}
                </span>
                <span
                  class="check-btn__subname"
                  :class="{ active: filter === 'price-high-to-low' }"
                >
                  {{ $t("sort-high") }}
                </span>
              </span>
              <input
                type="radio"
                name="price"
                value="price-high-to-low"
                v-model="filter"
              />
              <span class="check-btn__content">
                <span class="check-btn__box"></span>
              </span>
            </label>

            <label>
              <span class="check-btn__text">
                <span class="check-btn__name">
                  {{ $t("sort-price") }}
                </span>
                <span
                  class="check-btn__subname"
                  :class="{ active: filter === 'price-low-to-high' }"
                >
                  {{ $t("sort-low") }}
                </span>
              </span>
              <input
                type="radio"
                name="price"
                value="price-low-to-high"
                v-model="filter"
              />
              <span class="check-btn__content">
                <span class="check-btn__box"></span>
              </span>
            </label>
          </div>
        </div>
        <div class="popup__content" v-if="skillsFilter">
          <div class="check-btn" v-for="(item, index) in skills" :key="index">
            <label>
              <span class="check-btn__text">
                <span class="check-btn__name">
                  {{ $t(`stat-${item}`) }}
                </span>
              </span>
              <input
                type="radio"
                :value="item"
                name="recommended"
                v-model="filter"
              />
              <span class="check-btn__content">
                <span class="check-btn__box"></span>
              </span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageButton from "@/components/common/buttons/PageButton";
import Close from "@/components/icons/Close";
import CloseModalMixin from "@/mixins/common/CloseModalMixin";
import ShoppiesList from "@/components/common/ShoppiesList";
import api from "@/api/api";

export default {
  name: "SortModal",
  mixins: [CloseModalMixin],
  components: { Close, PageButton, ShoppiesList },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      phenotypeShortKey: "",
      eventItems: [],
      filter: this.type === "DEFAULT" ? "recommended" : "",
      skills: ["strength", "endurance", "agility", "intelligence"],
    };
  },
  computed: {
    defaultFilter() {
      return this.type === "DEFAULT";
    },
    skillsFilter() {
      return this.type === "SKILLS";
    },
  },
  watch: {
    filter(val) {
      this.$emit("change", val);
    },
  },
  methods: {
    applyAsFilter(phenotypeShortKey) {
      if (phenotypeShortKey === this.phenotypeShortKey) {
        this.phenotypeShortKey = "";
      } else {
        this.phenotypeShortKey = phenotypeShortKey;
      }
      this.$emit("sortByPhenotype", this.phenotypeShortKey);
    },
    async getMainEvent() {
      try {
        const response = await api.getMainEvent();
        this.eventItems = response.result;
        this.prizePool = +response.prizePool;
      } catch (e) {
        console.log(e);
      }
    },
    resetFilter() {
      this.filter = this.type === "DEFAULT" ? "recommended" : "";
    },
  },
  async mounted() {
    await this.getMainEvent();
  },
};
</script>

<style scoped></style>
